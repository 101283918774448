(function () {
  if(!$(".animation-background").length){return}
  let iconSkipForward = document.querySelector(".animation-background");
  let animationSkipForward = bodymovin.loadAnimation({
    container: iconSkipForward,
    renderer: "svg",
    loop: false,
    autoplay: false,
    path: "../img/animation/stars.json",
  });
  let $amount = document.querySelector(".get-amount__count");
  var swiper1 = new Swiper(".swiper-container", {
    slidesPerView: 4,
    centeredSlides: true,
    loop: true,
    spaceBetween: 0,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
    },
    on: {
      transitionEnd: function () {
        setTimeout(() => {
          iconSkipForward.classList.add("show");
          $amount.classList.add("changed");
          animationSkipForward.playSegments([0, 30], true);
          getAmount();
          setTimeout(() => {
            iconSkipForward.classList.remove("show");
            $amount.classList.remove("changed");
          }, 1000);
        })
      },
    },
  });
  $("#charge-value").on("submit", function (e) {
    e.preventDefault();
    console.log($("#charge-value input"));
    $.magnificPopup.open({
      items: {
        src: "#success-popup",
      },
      type: "inline",
      delegate: 'a',
      removalDelay: 500, 
      mainClass: 'mfp-zoom-in',
      midClick: true 
    });
  });
  if (!$(".get-amount__count").length) {
    return;
  }
  function getAmount() {
    let percent = $("#extra-percent").text();
    let activeCount = $(".swiper-slide-active .swiper-slide-container")
      .text()
      .replace(/[^0-9]/gi, "");
    let plusCount =
      parseInt(activeCount, 10) +
      (parseInt(activeCount, 10) * Math.floor(percent)) / 100;
    let $amount = $(".get-amount__count");
    $amount.html("$" + Math.ceil(plusCount));
    $("#charge-value input").val(activeCount);
  }
})();
