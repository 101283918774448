(function () {
  if (!$("#phone").length) {
    return;
  }

  var input = document.querySelector("#phone");
  var iti = window.intlTelInput(input, {
    // allowDropdown: false,
    // autoHideDialCode: false,
    // autoPlaceholder: "off",
    // dropdownContainer: document.body,
    // excludeCountries: ["us"],
    // formatOnDisplay: false,
    // geoIpLookup: function(callback) {
    //   $.get("http://ipinfo.io", function() {}, "jsonp").always(function(resp) {
    //     var countryCode = (resp && resp.country) ? resp.country : "";
    //     callback(countryCode);
    //   });
    // },
    hiddenInput: "full_number",
    // initialCountry: "auto",
    // localizedCountries: { 'de': 'Deutschland' },
    // nationalMode: false,
    onlyCountries: ["us", "gb", "ch", "ca", "il", "ua"],
    // placeholderNumberType: "MOBILE",
    // preferredCountries: ['cn', 'jp'],
    // separateDialCode: true,
  });
  input.addEventListener("input", function () {
    var btn = document.querySelector(".valid-phone");
    var valid = iti.isValidNumber();
    if (valid) {
      btn.removeAttribute("disabled");
    } else {
      btn.setAttribute("disabled", "disabled");
    }
  });
  $("#phone-template").on("submit", function (e) {
    e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#error-popup",
      },
      type: "inline",
      delegate: 'a',
      removalDelay: 500,
      mainClass: 'mfp-zoom-in',
      midClick: true 
    });
  });
})();
