(function () {
  if (!$(".seconds").length) {
    return;
  }
  $('#resend-code').on('click',function(){
    timer(10);
    switchResend('on');
  })
  timer(10);
  switchResend('on');
  function switchResend(state){
    if(state === 'on' && !$('.timer-on').hasClass('active')){
      $('.timer-off').removeClass('active');
      $('.timer-on').addClass('active');
    } else if (state === 'off' && !$('.timer-off').hasClass('active')){
      $('.timer-on').removeClass('active');
      $('.timer-off').addClass('active');
    } else {
      return
    }
  }
  function timer(seconds) {
    var _Seconds = seconds,
      int;
    $(".seconds").text(_Seconds);
    int = setInterval(function () {
      if (_Seconds > 0) {
        _Seconds--;
        $(".seconds").text(_Seconds);
      } else {
        clearInterval(int);
        switchResend('off')
      }
    }, 1000);
  }
})();
