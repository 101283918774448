$(document).ready(() => {
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./target-blank.js');
  require('./slider.js');
  require('./telephone.js');
  require('./resend-code.js');
  require('./varification.js');
  require('./popups.js');
});

// remove preloader
$(window).on('load', () => {
  $('.preloader').fadeOut();
});
